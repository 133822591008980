import Axios from "axios"

/**
 * Converts js object to form-body format
 * @param {*} obj object to convert to form format
 */
const objToFormBody = obj =>
  Object.keys(obj)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]))
    .join("&")

/**
 * Gets an access token from the LACTED.ORG site
 * @param {string} username username
 * @param {string} password password
 * @returns {Promise} res(token: string) || rej(error: string)
 */
async function getToken(username, password) {
  const payload = {
    grant_type: "password",
    username,
    password,
    client_id: "XMNHmuKOIk8PyBx0VQhnfsBh7RAb8cZVmhyCfetZ",
    client_secret: "Kae28yo8c1vvVa8isZgJdLfNyfx63zIMd1gmBuuQ",
  }

  return new Promise((res, rej) => {
    const formBody = objToFormBody(payload)
    const url = `${process.env.GATSBY_LACTED_URL}?oauth=token` // site that doesn’t send Access-Control-*
    Axios.post(url, formBody, {
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded",
        'Access-Control-Allow-Origin': '*'
      },
    })
      .then(({ data }) => {
        return res(data.access_token)
      })
      .catch(e => {
        return rej(e)
      })
  })
}

/**
 * Gets logged in user from LACTED.ORG site
 * @param {string} token auth0 access token
 * @returns {*} res(user: object) || rej(error: string)
 */
async function getUserFromToken(token) {
  return new Promise((res, rej) => {
    Axios.get(
      `${process.env.GATSBY_LACTED_URL}/wp-json/wp/v2/users/me?access_token=${token}`
    )
      .then(({ data: user }) => res(user))
      .catch(e => rej(e))
  })
}

/**
 * get list of products or error msg
 * @param {string} access_token
 * @param {Number} id
 * @returns {*} [products] || error
 */
async function getUserProducts(access_token, id) {
  return new Promise((res, rej) => {
    Axios.get(
      `${process.env.GATSBY_LACTED_URL}/wp-json/wc/v3/orders?access_token=${access_token}&customer=${id}`
    )
      .then(({ data }) => res(data?.flatMap(({ line_items }) => line_items)))
      .catch(e => rej(false))
  })
}

async function getProduct(id, token) {
  const { data } = await Axios.get(
    `${process.env.GATSBY_LACTED_URL}/wp-json/wc/v3/products/${id}/?access_token=${token}`
  ).catch(e => {
    console.error({ e })
    return false
  })

  return data
}

/**
 * EXPERIMENTAL
 * ============
 * This is the right way to refactor the codebase
 * This is stil a WIP and not implemented - but should serve as a guide
 *
 */

// async function userProducts(access_token, id) {
//   return new Promise((res, rej) => {
//     Axios.get(
//       `${process.env.GATSBY_LACTED_URL}/wp-json/wc/v3/orders?access_token=${access_token}&customer=${id}`
//     )
//       .then(({ data }) =>
//         res({
//           success: true,
//           data: data?.flatMap(({ line_items }) => line_items),
//         })
//       )
//       .catch(e => rej({ success: false, data: e }))
//   })
// }

/**
 *
 * clean this up to have universal success/failure functions
 */

// const withSuccess = success => obj => data => ({ ...obj, success, data })
// var succeed = withSuccess(true)
// var fail = withSuccess(false)

// async function login(username, password) {
//   var token = await getToken(username, password)
//   var data = { ...LOGIN_OBJ, token }
//   return await getUserFromToken(token)
//     .then(succeed(data))
//     .catch(fail(data))
// }

// async function loginAndGetData(username, password) {
//   // This and the next block look too similar to not have a unified abstraction
//   var loginResult = await login(username, password)
//   var { success, data: user, token } = loginResult
//   if (!success) return loginResult // data = error

//   var productResult = await userProducts(token, user.id)
//   var { success, data: product } = productResult
//   if (!success) return productResult // data = error

//   return { success, data: { user, product }, token } //data = payload
// }

export { getToken, getProduct, getUserFromToken, getUserProducts }
