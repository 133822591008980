/**********************************************************
 * 🧨 updateData 🧨
 * overrides a property of `data` and sets `loading = false`
 * @param {string} key property to update
 * @param {*} value update value
 */
function updateData(key, value) {
  return { loading: false, value: null, error: null, [key]: value }
}
//***********************************************************

/**********************************************************
 * 🧨 succeed 🧨
 * sets success property
 * @param {*} value success message
 */
function succeed(value) {
  return updateData("value", value)
}
//***********************************************************

/**********************************************************
 * 🧨 fail 🧨
 * sets fail property
 * @param {*} value success message
 */
function fail(error = "Operation Failed") {
  return updateData("error", error)
}
//***********************************************************

/**********************************************************
 * 🧨 update 🧨
 * updater function to pass to setter functions
 * from useState()
 * @param {object} updateObj override values
 */
function update(updateObj) {
  return function(previous) {
    return { ...previous, ...updateObj }
  }
}
//***********************************************************

/**********************************************************
 * 🧨 loading 🧨
 * updates the `loading` prop to true,
 * leaves rest of state in tact
 * @param {*} previous previousState
 */
function loading(previous) {
  return update({ loading: true })
}
//***********************************************************

/**********************************************************
 * 🧨 notLoading 🧨
 * updates the `loading` prop to false,
 * leaves rest of state in tact
 * @param {*} previous previousState
 */
function notLoading(previous) {
  return update({ loading: false })
}
//***********************************************************

/**********************************************************
 * 🧨 accessValid 🧨
 * Checks if hash and acces status are valid
 * @param {object} state current state
 */
function accessValid(state) {
  return state?.hasAccess
}
//***********************************************************
/**********************************************************
 * 🧨 isLoggedIn 🧨
 * Checks if user exists
 * @param {object} state current state
 */
function isLoggedIn(state) {
  return state?.user
}
//***********************************************************

/**********************************************************
 * 🧨 openLogin 🧨
 * dynamically provides either an updater fn or
 * updated state with login defaults
 * {title:"Login", value: true}
 * @param {*} prop titleString || previousState
 * @returns {object} updaterFn || updatedState
 */
function openLogin(prop) {
  return typeof prop === "string"
    ? update({ title: prop, valid: true }) // return updater fn with title partialy applied
    : { ...prop, title: "Login", value: true } // this is the return of updater fn
}
//***********************************************************

export {
  updateData,
  succeed,
  fail,
  update,
  loading,
  notLoading,
  accessValid,
  openLogin,
  isLoggedIn,
}
